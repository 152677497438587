import React, { useState, useEffect } from "react";
import { CloseButton } from "react-bootstrap";
import Checkout from "./Checkout";

const Banner = (props) => {
  // Pass the subscription information to the component as props

  return (
    //banner shows if users signs up first time, they see 'Thanks for signing up', between 1-13 days of trial they see their trial period, at 0 days they see the trial has ended.

      <div className="d-flex col bg-primary justify-content-space-between align-items-center p-1">
        <div className="d-flex mx-2 col justify-content-center align-items-center gap-2">
          {props.daysToTrial === 14 ? (
            <>
              <p className="m-0 bannerText">
                Thanks for signing up
                <span role="img" aria-label="Party Popper">
                  {"\uD83C\uDF89"}
                </span>{" "}
                Your 14 day free trial has begun.
              </p>
              {/* <Checkout email={props.email} name="Upgrade" /> */}
            </>
          ) : props.daysToTrial !== 0 ? (
            <>
              <p className="m-0 bannerText">
                Your trial ends in <span>{props.daysToTrial}</span> days.
                Upgrade to receive the daily emails
              </p>
              {/* <Checkout email={props.email} name="Upgrade" /> */}
            </>
          ) : (
            <>
              <p className="m-0 bannerText">
                Your free trial has ended. Upgrade to continue to receive the
                daily emails
              </p>
              {/* <Checkout email={props.email} name="Upgrade" /> */}
            </>
          )}
        </div>
        <CloseButton variant="white" onClick={() => props.handleBannerClose()}/>
      </div>
    
  );
};

export default Banner;
