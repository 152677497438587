import React from "react";
import { useState } from "react";
import DeletePing from "./DeletePing";
import EditPing from "./EditPing";

const PingCard = (props) => {
  const [show, setShow] = useState(props.show);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const checkBox = {
    "just listed": props.choices.some((arr) => arr === "just listed"),
    "just sold": props.choices.some((arr) => arr === "just sold"),
    "price change": props.choices.some((arr) => arr === "price change"),
    "unlisted": props.choices.some((arr) => arr === "unlisted"),
  };

  const handleSuccess = () => {
    setShow(false);
  };

  return (
    <div className="card" key={props.pingId}>
      <div className="card-body">
      <div className="d-flex flex-column flex-md-row align-items-start justify-content-between mb-2">
  <div className="d-inline-flex order-2 order-md-1 flex-wrap">
    {/* Rendering the suburbs choices */}
    {props.suburbs.map((c, index) => (
      <h5 key={index} className="card-title d-inline-flex mb-0">
        {c.split(",")[0]}
        {index !== props.suburbs.length - 1 && <>,&nbsp;</>}
      </h5>
    ))}
  </div>

  {props.isRental ? (
    <div className="badge rounded-pill text-bg-warning mb-2 mb-md-0 order-1 order-md-2 me-md-2">
      Rental ping
    </div>
  ) : (
    <div className="badge text-white rounded-pill text-bg-success mb-2 mb-md-0 order-1 order-md-2 me-md-2">
      Sales ping
    </div>
  )}
</div>

        <div className="container"></div>

        {/* Rendering the ping types on each of the cards */}
        <div className="d-flex flex-wrap">
          {props.choices.map((c, index) => (
            <span
              key={index}
              className="badge rounded-pill text-bg-light text-capitalize me-2 mb-2"
            >
              {c}
            </span>
          ))}
        </div>
        {props.isJustListed && (
          <span className="badge rounded-pill text-bg-light">Just Listed</span>
        )}
        {props.isPriceChange && (
          <span className="badge rounded-pill text-bg-light">Price Change</span>
        )}
        {props.isJustSold && (
          <span className="badge rounded-pill text-bg-light">Just Sold</span>
        )}
        {props.isUnlisted && (
          <span className="badge rounded-pill text-bg-light">Unlisted</span>
        )}

        {/* show if there is a min value selected only for rental */}
        {(props.isRental && props.minRentalPrice != null && props.minRentalPrice !== 0) && (
  <span className="badge rounded-pill text-bg-light">
    From ${props.minRentalPrice} per week
  </span>
)}
  {/* show if there is a min value selected only for rental */}
  {(!props.isRental && props.minSalesPrice != null && props.minSalesPrice !== 0) && (
  <span className="badge rounded-pill text-bg-light">
    From ${props.minSalesPrice.toLocaleString()} 
  </span>
)}
        {/* button section */}
        <div className="border-bottom pt-2"></div>

        <div className="container p-0 pt-2 d-flex justify-content-between">
          {/* delete ping  */}
          
          {props.isRental === true ? (
  (props.isSubscribed || props.daysToTrial > 0) && props.isRentalSubscribed ? (
    <div className="d-flex align-items-center">
      <div className="status-indicator bg-success me-2"></div>
      <span className="me-2 text-secondary">Running</span>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <div className="status-indicator bg-warning me-2"></div>
      <span className="me-2 text-secondary">Paused</span>
    </div>
  )
) : (
  (props.isSubscribed || props.daysToTrial > 0) ? (
    <div className="d-flex align-items-center">
      <div className="status-indicator bg-success me-2"></div>
      <span className="me-2 text-secondary">Running</span>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <div className="status-indicator bg-warning me-2"></div>
      <span className="me-2 text-secondary">Paused</span>
    </div>
  )
)}

          

          <div className="d-flex gap-1 align-items-center">
            <button
              type="button"
              className="btn btn-light d-flex align-items-center"
              onClick={handleShow}
            >
              <svg
                width="16"
                className="m-1"
                height="16"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <g clipPath="url(#clip0_234_2788)">
                  <path d="M12.1458 0.145917C12.1923 0.099354 12.2474 0.0624111 12.3082 0.0372047C12.3689 0.0119982 12.434 -0.000976563 12.4998 -0.000976562C12.5656 -0.000976562 12.6307 0.0119982 12.6914 0.0372047C12.7522 0.0624111 12.8074 0.099354 12.8538 0.145917L15.8538 3.14592C15.9004 3.19236 15.9373 3.24754 15.9625 3.30828C15.9877 3.36903 16.0007 3.43415 16.0007 3.49992C16.0007 3.56568 15.9877 3.63081 15.9625 3.69155C15.9373 3.7523 15.9004 3.80747 15.8538 3.85392L5.85381 13.8539C5.80582 13.9016 5.74867 13.939 5.68581 13.9639L0.685806 15.9639C0.594942 16.0003 0.495402 16.0092 0.399526 15.9895C0.303649 15.9699 0.215653 15.9225 0.146447 15.8533C0.0772403 15.7841 0.0298668 15.6961 0.0101994 15.6002C-0.0094681 15.5043 -0.000564594 15.4048 0.0358061 15.3139L2.03581 10.3139C2.06073 10.2511 2.09816 10.1939 2.14581 10.1459L12.1458 0.145917V0.145917ZM11.2068 2.49992L13.4998 4.79292L14.7928 3.49992L12.4998 1.20692L11.2068 2.49992ZM12.7928 5.49992L10.4998 3.20692L3.99981 9.70692V9.99992H4.49981C4.63241 9.99992 4.75959 10.0526 4.85336 10.1464C4.94713 10.2401 4.99981 10.3673 4.99981 10.4999V10.9999H5.49981C5.63241 10.9999 5.75959 11.0526 5.85336 11.1464C5.94713 11.2401 5.99981 11.3673 5.99981 11.4999V11.9999H6.29281L12.7928 5.49992V5.49992ZM3.03181 10.6749L2.92581 10.7809L1.39781 14.6019L5.21881 13.0739L5.32481 12.9679C5.22943 12.9323 5.1472 12.8684 5.08912 12.7847C5.03104 12.7011 4.99988 12.6017 4.99981 12.4999V11.9999H4.49981C4.3672 11.9999 4.24002 11.9472 4.14625 11.8535C4.05248 11.7597 3.99981 11.6325 3.99981 11.4999V10.9999H3.49981C3.39799 10.9998 3.29862 10.9687 3.21498 10.9106C3.13135 10.8525 3.06744 10.7703 3.03181 10.6749V10.6749Z" />
                </g>
              </svg>{" "}
              Edit
            </button>
            <DeletePing card={props.id} delete={props.delete} />
          </div>
        </div>


        {/* Edit ping button to edit that passes down the props of suburb choices and the ping types */}

        <EditPing
          suburbs={props.suburbs}
          pingId={props.pingId}
          choices={props.choices}
          isJustListed={checkBox["just listed"]}
          isJustSold={checkBox["just sold"]}
          isPriceChange={checkBox["price change"]}
          isUnlisted={checkBox["unlisted"]}
     
          //passing down rental attributes
          minPrice={ props.isRental ? props.minRentalPrice : props.minSalesPrice}
          isRental={props.isRental}
          isRentalSubscribed = {props.isRentalSubscribed}

          // opening and closing the modal
          show={show}
          close={handleClose}
          pingName="Edit Ping"
          buttonName="Save Changes"
          //when the user hits create ping, function to update the DB
          apply={props.apply}
          cardId={props.id}
          handleSuccess={handleSuccess}
          notificationEmail={props.notificationEmail}
        />
      </div>
    </div>
  );
};

export default PingCard;
