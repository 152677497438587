// shows a modal popup when a subscription fails and a redirect from stripe
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Checkout from "../Checkout";

function PayementFailed(props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered // Aligns modal content vertically and horizontally
        fullscreen="md" // Makes the modal fullscreen on smaller devices
      >
        <Modal.Body className="d-flex mt-5 mb-5 m-2 row gap-3 justify-content-center align-items-center">
          <div className="text-center mb-4">
            <svg
              width="128"
              height="128"
              viewBox="0 0 128 128"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="128" height="128" />
              <g clipPath="url(#clip0_4_4683)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M-5 29C-5 20.1634 2.16344 13 11 13H107C115.837 13 123 20.1634 123 29L-5 29ZM-5 37H123V71.8094C116.629 65.7318 108 62 98.5 62C78.8939 62 63 77.8939 63 97.5C63 102.279 63.9445 106.838 65.657 111H11C2.16344 111 -5 103.837 -5 95V37Z"
                  fill="#F4F0FF"
                />
                <rect x="7" y="45" width="43" height="8" rx="4" fill="white" />
                <rect x="55" y="45" width="16" height="8" rx="4" fill="white" />
                <path
                  d="M113.293 123.542C119.724 119.829 124.417 113.713 126.339 106.54C128.261 99.3668 127.255 91.724 123.542 85.2929C119.829 78.8617 113.713 74.1689 106.54 72.2469C99.3668 70.3249 91.724 71.3311 85.2929 75.0441C78.8617 78.7572 74.169 84.8729 72.247 92.0459C70.325 99.2189 71.3311 106.862 75.0442 113.293C78.7572 119.724 84.8729 124.417 92.046 126.339C99.219 128.261 106.862 127.255 113.293 123.542Z"
                  fill="#DC3545"
                />
                <rect
                  x="113.284"
                  y="91"
                  width="32"
                  height="8"
                  rx="4"
                  transform="rotate(135 113.284 91)"
                  fill="white"
                />
                <rect
                  width="32"
                  height="8"
                  rx="4"
                  transform="matrix(0.707107 0.707107 0.707107 -0.707107 85.2842 90.6572)"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_4_4683">
                  <rect width="128" height="128" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className="text-center">
            <h3>Payment Failed</h3>
            <p>
              Your payment for proping daily emails did not go through. Please
              try again.
            </p>
            {/* <Checkout name="Try again" email={props.email} /> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PayementFailed;
  