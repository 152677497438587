import React, { useState } from "react";
import EditPing from "./EditPing";
import mixpanel from 'mixpanel-browser';

const NewPing = (props) => {
  const [show, setShow] = useState(props.show);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

    //mixpanel track click 
    mixpanel.track('ping_click')
  }

  const handleSuccess = () => {
    setShow(false);
  }

  return (
    <div>
      <>
        <button
          type="button"
          onClick={handleShow}
          className="d-flex align-items-center btn btn-primary"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 8.5C16.2652 8.5 16.5196 8.60536 16.7071 8.79289C16.8946 8.98043 17 9.23478 17 9.5V15.5H23C23.2652 15.5 23.5196 15.6054 23.7071 15.7929C23.8946 15.9804 24 16.2348 24 16.5C24 16.7652 23.8946 17.0196 23.7071 17.2071C23.5196 17.3946 23.2652 17.5 23 17.5H17V23.5C17 23.7652 16.8946 24.0196 16.7071 24.2071C16.5196 24.3946 16.2652 24.5 16 24.5C15.7348 24.5 15.4804 24.3946 15.2929 24.2071C15.1054 24.0196 15 23.7652 15 23.5V17.5H9C8.73478 17.5 8.48043 17.3946 8.29289 17.2071C8.10536 17.0196 8 16.7652 8 16.5C8 16.2348 8.10536 15.9804 8.29289 15.7929C8.48043 15.6054 8.73478 15.5 9 15.5H15V9.5C15 9.23478 15.1054 8.98043 15.2929 8.79289C15.4804 8.60536 15.7348 8.5 16 8.5Z"
              fill="#ffffff"
            />
          </svg>
          Create Ping
        </button>
      </>
      <EditPing
        // opening and closing the modal
        show={show}
        close={handleClose}

        //name of modal n button
        pingName = "New Ping"
        buttonName = "Create Ping"
        notificationEmail={props.notificationEmail}

        //when the user hits create ping, function to update the DB
        apply = {props.apply}
        handleSuccess={handleSuccess}
        isRentalSubscribed = {props.isRentalSubscribed}

      />
    </div>
  );
};

export default NewPing;
