import React from "react";
import Form from "react-bootstrap/Form";
import Navbar from "./Navbar";
import { Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import Checkout from "./Checkout";

const endpointUrl = process.env.REACT_APP_SERVER_URL;

const AccountSettings = (props) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [notificationEmail, setNotificationEmail] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [isSubscribed, setIsSubscribed] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEnterpriseCustomer, setIsEnterpriseCustomer]= useState([]);
  const [showButtons, setShowButtons] = useState(false);

  //code to call the user details api from the backend and save it in the state
  const accessToken = localStorage.getItem("BE_token");
  const userId = localStorage.getItem("user_id");

  const customerInfo = {
    //need to get customer details from the backend
    customerId: stripeId,
    returnUrl: window.location.origin,
  };

  async function getUser() {
    // Check if the token exists
    setIsLoading(true);
   
    if (accessToken && userId) {
      try {
        // Make a GET request with the token in the header
        const response = await fetch(
          `https://app.proping.com.au/users/${userId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Check if the response is positive
        if (!response.ok) {
          throw new Error(response.status);
        }

        // Parse the response data as JSON and log it
        const data = await response.json();
        // setting the userData to state
        setFname(data.given_name);
        setLname(data.family_name);
        setNotificationEmail(data.email);
        setStripeId(data.stripe_customer_id);
        setIsSubscribed(data.last_invoice_paid || (data.organization_id !== null && data.organization_id !== ''));
        setIsEnterpriseCustomer(data.organization_id);
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching pings:", error);
      }
    } else {
      console.error("Backend or UserId token not found in local storage");
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  async function handleBilling() {
    await fetch(`${endpointUrl}/billing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerInfo),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to get checkout URL");
        }
      })
      .then((res) => {
        if (res.url) {
          window.location.assign(res.url);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // Redirect to the Stripe Checkout page
  }

  return (
    <>
      <Navbar
        email={notificationEmail}
        customerId={stripeId}
        isSubscribed={isSubscribed}
      />

      <div className="container-sm">
        <div className="container-sm bg-light p-0 pt-5 pb-2 d-flex justify-content-between align-items-center">
          <h4>Account Settings</h4>
        </div>
        <div className="border-bottom"></div>

       {isLoading ? <Spinner/> : (
        <div className="container-md p-0 pt-3 pb-5">
          <div className="card container pb-5">
            {/* Main settings form section  */}
            <small className="form-text text-muted mt-4">
              Personal information
            </small>
            <Form>
              <Form.Group className="mt-4">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder=""
                  disabled
                  readOnly
                  defaultValue={fname}
                  // onChange={(e) => {
                  //   setFname(e.target.value);
                  //   setShowButtons(true);
                  // }}
                />
              </Form.Group>

              <Form.Group className="mt-4">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder=""
                  disabled
                  readOnly
                  defaultValue={lname}
                  // onChange={(e) => {
                  //   setLname(e.target.value);
                  //   setShowButtons(true);
                  // }}
                />
              </Form.Group>

              <Form.Group className="mt-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  aria-label="Disabled input example"
                  disabled
                  readOnly
                  defaultValue={notificationEmail}
                  // onChange={(e) => {
                  //   setNotificationEmail(e.target.value);
                  //   setShowButtons(true);
                  // }}
                />
                <Form.Text>We will send pings to this email address</Form.Text>
              </Form.Group>
              {showButtons && (
                <div className="d-flex justify-content-end mt-2">
                  <button className="btn btn-light m-1" type="submit">
                    Cancel
                  </button>
                  <button className="btn btn-primary m-1" type="submit">
                    Save
                  </button>
                </div>
              )}
            </Form>

            {/* divider  */}
            <div className="border-bottom mt-2"></div>

            {/* Subscription settings section  */}
            <div className="mt-4">
              <small className="form-text text-muted">Subscription</small>

              {/* if User has no stripe id and has no invoice paid, lets show checkout button otherwise send them to manage subscription */}
              {!isSubscribed && (!stripeId || stripeId === "") ? (
                <div className="mt-4">
                  {/* <Checkout
                    email={notificationEmail}
                    name="Upgrade to premium"
                    customerId={props.customerId}
                  /> */}
                </div>
              ) : ( 
                !isEnterpriseCustomer ? (
<button
                  type="button"
                  className="  btn btn-light mt-2 px-0 d-flex align-items-center"
                  onClick={handleBilling}
                >
                  Manage Subscriptions
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                    />
                  </svg>
                </button>
                ):(
                  <p>Please contact your account admin to manage subscription</p>
                )
              )
                
              }
            </div>
          </div>
        </div>
       )}
      </div>
    </>
  );
};

export default AccountSettings;
