import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// import './PriceSlider.css'; // Optional: for additional custom styles

const PriceSlider = (props) => {
  const handleSliderChange = (value) => {
    props.setMinPrice(value);
  };

  const getPriceLabel = (price, isRental) => {
    if (isRental) {
      // If isRental is true
      if (price === 0 || price == null) return "Any Price / week";
      return `Over ${price} / week`;
    } else {
      // If isRental is false
      if (price === 0 || price == null) return "Any Price";
      return `Over ${price.toLocaleString()}`; // Add commas for sale prices

    }
  };
  // useEffect(() => {
  //   props.setMinPrice(0); // Reset slider value to 0
  // }, [props.isRental]); // Effect runs when isRental changes

  // Determine slider settings based on whether it's rental or sale
  const sliderSettings = props.isRental
    ? { min: 0, max: 2000, step: 200 } // Rental settings
    : { min: 0, max: 2000000, step: 100000 }; // Sale settings

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Slider
            min={sliderSettings.min}
            max={sliderSettings.max}
            step={sliderSettings.step}
            value={props.minPrice ?? sliderSettings.min}
            onChange={handleSliderChange}
            styles={{
              rail: {
                height: 6,
              },
              track: {
                height: 6,
              },
              handle: {
                borderWidth: 3,
                width: 22,
                height: 22,
                marginTop: -8,
              },
            }}
          />
          <div className="text-center">
            {getPriceLabel(props.minPrice, props.isRental)}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PriceSlider;
