// useAuth.js
import { useState, useEffect, useCallback } from "react";

export const useAuth = () => {
  const [accessToken, setAccessToken] = useState(null);


  const onTokenStored = useCallback(() => {
    const token = localStorage.getItem("BE_token");
    setAccessToken(token);
  }, []);

  useEffect(() => {
    onTokenStored();
  }, [onTokenStored]);

  return {
    accessToken,
    onTokenStored,
  };
};
