import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "react-bootstrap";
import React from "react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Dropdown.Item
      onClick={() => {
        localStorage.removeItem("BE_token");
        logout({ returnTo: "https://proping.com.au/" });
      }}
    >
      Log out
    </Dropdown.Item>
  );
};

export default LogoutButton;
