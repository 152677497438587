import React from "react";
import Navbar from "./Navbar";
import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";

const Faq = (props) => {
  const [faqData, setFaqData] = useState([]);
  const [notificationEmail, setNotificationEmail] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [isSubscribed, setIsSubscribed] = useState();

  //code to call the user details api from the backend and save it in the state
  const accessToken = localStorage.getItem("BE_token");
  const userId = localStorage.getItem("user_id");

  async function getUser() {
    // Check if the token exists
    if (accessToken && userId) {
      try {
        // Make a GET request with the token in the header
        const response = await fetch(
          `https://app.proping.com.au/users/${userId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Check if the response is positive
        if (!response.ok) {
          throw new Error(response.status);
        }

        // Parse the response data as JSON and log it
        const data = await response.json();

        // setting the userData to state
        setNotificationEmail(data.email);
        setStripeId(data.stripe_customer_id);
        setIsSubscribed(data.last_invoice_paid);

      } catch (error) {
        console.error("Error fetching pings:", error);
      }
    } else {
      console.error("Backend or UserId token not found in local storage");
    }
  }

  const fetchData = () => {
    fetch(
      "https://raw.githubusercontent.com/heyproping/proping-assets/main/assets/faq.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setFaqData(data);
        // Use the videoURL as needed
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
    getUser();
  }, []);

  return (
    <>
      <Navbar
        email={notificationEmail}
        customerId={stripeId}
        isSubscribed={isSubscribed}
      />
      <div className="container-sm">
        <div className="container-sm bg-light p-0 pt-5 pb-2 d-flex justify-content-between align-items-center">
          <h4>FAQ</h4>
        </div>
        <div className="border-bottom"></div>

        <div className="container-md p-0 pt-3 pb-5">
          <Accordion defaultActiveKey="0">
            {faqData.map((faq, index) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Faq;
