// shows a modal popup when a subscription is complete and a redirect from stripe with a success

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function PayementSuccess(props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
    window.history.replaceState({}, document.title, window.location.pathname);
  }
  
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered // Aligns modal content vertically and horizontally
        fullscreen="md" // Makes the modal fullscreen on smaller devices
      >
        <Modal.Body className="d-flex mt-5 mb-5 m-2 row gap-3 justify-content-center align-items-center">
          <div className="text-center mb-4">
            <svg
              width="128"
              height="128"
              viewBox="0 0 128 128"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="128" height="128" />
              <g clipPath="url(#clip0_4_4683)">
                <path
                  d="M126.339 106.54C124.417 113.713 119.724 119.829 113.293 123.542C106.862 127.255 99.219 128.261 92.046 126.339C84.8729 124.417 78.7572 119.724 75.0442 113.293C71.3311 106.862 70.325 99.2189 72.247 92.0459C74.169 84.8729 78.8617 78.7572 85.2929 75.0441C91.724 71.3311 99.3668 70.3249 106.54 72.2469C113.713 74.1689 119.829 78.8617 123.542 85.2929C127.255 91.724 128.261 99.3668 126.339 106.54ZM114.415 89.4389C114.049 89.0613 113.613 88.7595 113.131 88.5506C112.649 88.3417 112.13 88.2298 111.605 88.2213C111.08 88.2128 110.558 88.3079 110.069 88.5012C109.581 88.6944 109.135 88.982 108.757 89.3476L95.6788 101.993L92.5845 96.634C92.0534 95.7141 91.1786 95.0429 90.1526 94.7679C89.1266 94.493 88.0334 94.6369 87.1135 95.168C86.1936 95.6991 85.5223 96.5739 85.2474 97.5999C84.9725 98.6259 85.1164 99.7191 85.6475 100.639L90.028 108.215C90.4834 109.005 91.1131 109.682 91.8692 110.192C92.6253 110.703 93.4878 111.034 94.3913 111.161C95.2947 111.289 96.2153 111.208 97.0829 110.926C97.9505 110.644 98.7423 110.167 99.3981 109.533L114.323 95.096C114.701 94.7306 115.003 94.2944 115.212 93.8123C115.42 93.3302 115.532 92.8117 115.541 92.2863C115.549 91.761 115.454 91.2391 115.261 90.7506C115.068 90.262 114.78 89.8163 114.415 89.4389Z"
                  fill="#28A745"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M-5 29C-5 20.1634 2.16344 13 11 13H107C115.837 13 123 20.1634 123 29L-5 29ZM-5 37H123V71.8094C116.629 65.7318 108 62 98.5 62C78.8939 62 63 77.8939 63 97.5C63 102.279 63.9445 106.838 65.657 111H11C2.16344 111 -5 103.837 -5 95V37Z"
                  fill="#F4F0FF"
                />
                <rect x="7" y="45" width="43" height="8" rx="4" fill="white" />
                <rect x="55" y="45" width="16" height="8" rx="4" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_4_4683">
                  <rect width="128" height="128" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className="text-center">
            <h3>Thanks for subscribing</h3>
            <p>
              Your subscription was successfully created. You can expect an
              email every morning.
            </p>

            <button
              className="btn btn-outline-primary col-4"
              type="button"
              onClick={() => handleClose()}
            >
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PayementSuccess;
