import React from "react";
import "./App.css";
import { useAuth } from "./Components/useAuth";


// import { useAuth } from "./Components/useAuth";


import CallBack from "./Components/callBack";
import mixpanel from "mixpanel-browser";

import HomePage from "./Components/HomePage";
// import Spinner from "react-bootstrap/Spinner";
import AccountSettings from "./Components/AccountSettings";
import Faq from "./Components/faq"
import { Routes, Route } from "react-router-dom";


  //mixpanel tracking
  mixpanel.init("114f41dfda4de92a266c82f4005eee15")

function App() {
  const { accessToken, onTokenStored } = useAuth();

  if (!accessToken) {
    return <CallBack onTokenStored={onTokenStored} />;
  }

  return (
      <div className="App">
        <Routes>
        <Route path="/callback" element={<CallBack onTokenStored={onTokenStored} />} />
        {accessToken && (
          <>
            {/* Defining the various pages n path URL in the React app */}
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/settings" element={<AccountSettings />} />
          </>
        )}
      </Routes>
        
      </div>
    )
  ;
}

export default App;
