import React, { useState } from "react";
import { useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SearchBar from "./SearchBar";
import axios from "axios";
import debounce from "lodash/debounce";
import PingTypeSelector from "./PingTypeSelector";
import PriceSlider from "./PriceSlider";

const endpointUrl = process.env.REACT_APP_SERVER_URL;

const EditPing = ({ apply, handleSuccess, ...props }) => {
  const [searchResults, setSearchResults] = useState([]);
  //list of new selected choices
  const [suburbChoices, setSuburbChoices] = useState(props.suburbs || []);
  // const [usersEmail, setUsersEmail] = useState([""]);

  const [justListed, setJustListed] = useState(props.isJustListed || true);
  const [justSold, setJustSold] = useState(props.isJustSold || true);
  const [priceChange, setPriceChange] = useState(props.isPriceChange || true);
  const [unlisted, setUnlisted] = useState(props.isUnlisted || true);
  const [underOffer, setUnderOffer] = useState(props.isUnderOffer || true);
  const [over90Days, setOver90Days] = useState(props.isOver90Days || true);

  const [maxSuburbError, setMaxSuburbError] = useState(false);
  const [noInfoError, setNoInfoError] = useState(false);
  const [unableToCreate, setunableToCreate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // choosing type of alerts

  const [minPrice, setMinPrice] = useState(props.minPrice ?? 0);
  const [isRental, setIsRental] = useState(props.isRental ?? false);

  const cardId = props.cardId || null;

  const searchSuburbs = async (term) => {
    const options = {
      method: "get",
      url: `${endpointUrl}/search?q=${term}`,
    };

    try {
      const response = await axios(options);
      return setSearchResults(response.data.slice(0, 20));
    } catch (error) {
      console.error(error);
    }
  };




  const debouncedSearchSuburbs = debounce(searchSuburbs, 400);

  //code to handle user searching for a suburb
  const handleSearchChange = (e) => {
    setSearchResults([]);
    setSearchTerm(e.target.value);
    if (e.target.value.length < 3) {
      setSearchResults([]);
    } else if (e.target.value.length >= 3) {
      debouncedSearchSuburbs(e.target.value);
    }
  };

  //clear search term
  const handleClear = () => {
    setSearchResults([]);
    setSearchTerm("");
  };

  //code to set the chip functionality. When the user selects the search results a chip is created
  function handleSuburbChoice(choice) {
    setSearchResults([]);
    setSearchTerm("");

    if (suburbChoices.includes(choice)) {
      setSuburbChoices(suburbChoices.filter((c) => c !== choice));
    } else {
      if (suburbChoices.length >= 10) {
        setMaxSuburbError(true);
        return;
      }
      setSuburbChoices([...suburbChoices, choice]);
    }
    setMaxSuburbError(false);
  }

  //code to submit the suburb choices
  const handleSubmit = async () => {
    if (
      suburbChoices.length === 0 ||
      (!justListed && !unlisted && !priceChange && !underOffer && !over90Days)
    ) {
      setNoInfoError(true);
    } else {
      setNoInfoError(false);

      const success = await apply(
        suburbChoices,
        justListed,
        justSold,
        priceChange,
        underOffer,
        over90Days,
        unlisted,
        minPrice || 0,
        isRental,
        cardId
      );
      if (success) {
        handleSuccess();
        // setSuburbChoices([]);
        // setJustListed(false);
        // setJustSold(false);
        // setPriceChange(false);
        // setUnlisted(false);
      } else {
        setunableToCreate(true);
      }
    }
  };

  const containerRef = useRef();
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={props.show}
        onHide={props.close}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{props.pingName}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mb-5">
          {maxSuburbError && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                viewBox="0 0 16 16"
                role="img"
                aria-label="Warning:"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <div>You can only select a maximum of 10 suburbs per ping</div>
            </div>
          )}
          {noInfoError && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                viewBox="0 0 16 16"
                role="img"
                aria-label="Warning:"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <div>
                Please add atleast 1 suburb and choose a type of ping you want
                to receive
              </div>
            </div>
          )}
          {unableToCreate && (
            <div
              className="alert alert-danger d-flex align-items-center"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                viewBox="0 0 16 16"
                role="img"
                aria-label="Warning:"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <div>Unable to create ping. Please try again later.</div>
            </div>
          )}
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
              ref={containerRef}
            >
              <Form.Label>Add suburbs</Form.Label>
              <SearchBar
                suburbChoices={suburbChoices}
                suburbs={props.suburbs}
                choices={handleSuburbChoice}
                //search term and results
                searchResults={searchResults}
                searchChange={handleSearchChange}
                clear={handleClear}
                searchTerm={searchTerm}
              />
            </Form.Group>
            {/* Here goes the new rental choice feauture when enabled  */}
            {props.isRentalSubscribed ? (
              <>
                <Form.Group
                  className="mb-2 pt-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Ping type</Form.Label>
                  <p className="text-muted">
                    Choose what type of alerts you want to receive
                  </p>
                  {/* Here goes the new rental choice feature when enabled */}
                  <PingTypeSelector
                    isRental={isRental}
                    setIsRental={setIsRental}
                    setMinPrice={setMinPrice}
                  />
                </Form.Group>

                {isRental ? (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-2 pt-4">
                      {" "}
                      Rental Price range
                    </Form.Label>
                    <p className="text-muted">
                      Refine your price range. We will only show you rental
                      listings above your selected minimum price
                    </p>
                    <PriceSlider
                     minPrice={minPrice}
                     setMinPrice={setMinPrice}
                     isRental={isRental}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label className="mb-2 pt-4">
                      Sales Price range
                    </Form.Label>
                    <p className="text-muted">
                      Refine your price range. We will only show you sales listings
                      above your selected minimum price
                    </p>
                    <PriceSlider
                             minPrice={minPrice}
                             setMinPrice={setMinPrice}
                             isRental={isRental}
                    />
                  </Form.Group>
                )}
              </>
            ):(
              <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label className="mb-2 pt-4">
                Sales Price range
              </Form.Label>
              <p className="text-muted">
                Refine your price range. We will only show you sales listings
                above your selected minimum price
              </p>
              <PriceSlider
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    isRental={isRental}
              />
            </Form.Group>
            )}

            {/* <div>
              <p className="mb-2 pt-4">Ping categories</p>
              <p className="my-1 text-muted">
                We track the market for the following changes
              </p>
              <ul>
                <li>Price change</li>
                <li>Newly listed</li>
                <li>Listings over 90 days</li>
                <li>Under offer</li>
                <li>Sold</li>
                <li>Unlisted</li>
              </ul>
            </div> */}

            {/* <Form.Group className="pt-4">
              <Form.Label>Choose ping type</Form.Label>
              <p className="text-muted">
                Choose the types of pings you want to recieve
              </p>
              <div className="mb-3">
          
              <Form.Check
                  className="mb-2"
                  checked={priceChange}
                  type="checkbox"
                  id="Price change"
                  label="Price change"
                  onChange={() => setPriceChange(!priceChange)}
                />
                <Form.Check
                  className="mb-2"
                  checked={justListed}
                  type="checkbox"
                  id="Newly listed"
                  label="Newly listed"
                  onChange={() => setJustListed(!justListed)}
                />
                <Form.Check
                  className="mb-2"
                  checked={over90Days}
                  type="checkbox"
                  id="Over 90 days listed"
                  label="Over 90 days listed"
                  onChange={() => setPriceChange(!over90Days)}
                />
                <Form.Check
                  className="mb-2"
                  checked={underOffer}
                  type="checkbox"
                  id="under Offer"
                  label="Under offer"
                  onChange={() => setJustSold(!underOffer)}
                />
                
                <Form.Check
                  className="mb-2"
                  checked={unlisted}
                  type="checkbox"
                  id="Unlisted"
                  label="Sold/Unlisted"
                  onChange={() => setUnlisted(!unlisted)}
                />
              </div>
            </Form.Group> */}

            {/* Email preference */}
            <Form.Group className="pt-4">
              <Form.Label>Email sent to:  <span className="text-primary"> {props.notificationEmail}</span></Form.Label>
              <p className="text-muted">
                The ping email will be sent to this address. This is based on
                your login email address.
              </p>
              {/* <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-light d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
                  </svg>
                </button>
              </div> */}
            </Form.Group>

            {/* Ping preference */}
            {/* <Form.Group className="pt-4">
                <Form.Label>Ping preference</Form.Label>
                <p className="text-muted">
                  Choose if you want to recieve all pings in one email or based on
                  ping type.
                </p>
                <div className="mb-3"> */}
            {/* Map the ping titles from the back end */}
            {/* <Form.Check
                    type="checkbox"
                    id="ping-preference"
                    label="Default"
                  />
                </div>
              </Form.Group> */}
          </Form>
        </Modal.Body>

        <Modal.Footer className="border-0 ">
          <div className="d-grid col">
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              type="button"
            >
              {props.buttonName}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditPing;
