import React from 'react';
const Chip = (props) => {
    return (
       props.suburbChoices.map(choice => (
                <div key={choice}>
                    <div className="m-1">
                        <span className="badge rounded-pill text-bg-light d-flex align-items-center justify-content-between">{choice}
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => props.choices(choice)} ></button>
                        </span>
                    </div>
                </div>
            ))
        

    );
}

export default Chip;
