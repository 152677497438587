import React from "react";


const SearchResult = (props) => {

  return (
    <div
      style={{ maxHeight: "450px", overflowY: "auto", zIndex:"10" }}
      className="card shadow mt-2 position-absolute  start-0 end-0"
    >
      <ul className="list-group border-0">
        <small className="list-group-item text-uppercase border-0">
          Results
        </small>
        {props.searchResults.map((c, index ) => (
          <li
            key={index}
            onClick={() => {
       
              const combinedChoice = `${c.name},${c.postcode}`;
              
              props.choices(combinedChoice);
             
            }}
            style={{ cursor: "pointer" }}
            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-0 "
          >
            {c.name}, {c.state.toUpperCase()} {c.postcode}
            <svg
              width="16"
              height="16"
              fill="#443894"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResult;
