import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "react-bootstrap/Spinner";

const Callback = (props) => {
  const { getAccessTokenSilently, isLoading, error, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const getToken = async () => {
      try {
        // Get token from Auth0
        const auth0Token = await getAccessTokenSilently();

        if (auth0Token) {
          const response = await fetch("https://app.proping.com.au/login", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${auth0Token}`,
            },
          });

          // Check if response is successful
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }

          const jsonData = await response.json();
      
          const backendToken = jsonData["BE access Token"];
          const userId = jsonData.user.id;
          
          
          localStorage.setItem("user_id", userId);
          localStorage.setItem("BE_token", backendToken);
          props.onTokenStored(backendToken);
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        // Redirect to login page with specific error message
        loginWithRedirect({ error: "Failed to retrieve token" });
      }
    };

    if (!isLoading && !error) {
      getToken();
    }
  }, [isLoading, error, getAccessTokenSilently, loginWithRedirect, navigate, props]);

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return null;
};

export default Callback;
