import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function VideoTutorial(props) {
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const fetchVideoURL = () => {
    fetch('https://raw.githubusercontent.com/heyproping/proping-assets/main/assets/config.json')
      .then(response => response.json())
      .then(data => {
        setVideo(data.videoURL);
        // Use the videoURL as needed
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchVideoURL();

  return (
    
    <>
      <Button variant="outline-primary" onClick={handleShow}>
     How it works
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="m-2">
            <iframe
              src={video}
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
              style={{
                position: "relative",
                width: "100%",
                height: "500px",
              }}
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VideoTutorial;
