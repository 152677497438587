import React from "react";
import Checkout from "./Checkout";
import { Card } from "react-bootstrap";
import VideoTutorial from "./VideoTutorial";

const Announcement = (props) => {
  return (
    <div className="d-flex flex-wrap justify-content-between align-items-start">
      <div>
      <h4>{props.title}</h4>
      <p className="mainDescription text-secondary">{props.text}</p>
      </div>
      {/* {props.buttonText.length > 0 && (
        <Checkout email={props.email} name={props.buttonText} />
      )} */}
      {props.video && <VideoTutorial video={props.video} />}
    </div>
  );
};

export default Announcement;

//Lets get you started by creating your ping, We have signed you up on a 14 day free trial.  watch a 1 video