import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function SuccessPing(props) {
  const [successhow, setSuccessShow] = useState(props.show);

  const handleClose = () => setSuccessShow(false);
 

  return (
    <>
      <Modal
        show={successhow}
        centered // Aligns modal content vertically and horizontally
        fullscreen="md" // Makes the modal fullscreen on smaller devices
      >
        <Modal.Body className="d-flex mt-5 mb-5 m-2 row gap-3 justify-content-center align-items-center">
          <div className="text-center mb-4">
            <svg
              width="128"
              height="128"
              viewBox="0 0 128 128"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect fill="#F5F5F5" />
              <g filter="url(#filter0_d_4_4683)">
                <rect fill="white" />
                <g clipPath="url(#clip0_4_4683)">
                  <path
                    d="M0.4 28.44C1.20655 24.9059 3.18924 21.7505 6.02337 19.4905C8.8575 17.2304 12.3751 15.9998 16 16H112C115.625 15.9998 119.142 17.2304 121.977 19.4905C124.811 21.7505 126.793 24.9059 127.6 28.44L64 67.312L0.4 28.44ZM0 37.576V94.408L46.424 65.944L0 37.576ZM54.088 70.64L1.528 102.848C2.82547 105.588 4.87457 107.903 7.4367 109.523C9.99883 111.143 12.9685 112.002 16 112H66.048C64.688 108.146 63.9954 104.087 64 100C63.9945 94.7448 65.1422 89.5524 67.3622 84.789C69.5821 80.0257 72.8202 75.8075 76.848 72.432L73.912 70.632L64 76.688L54.088 70.632V70.64ZM128 37.576V77.368C124.628 73.1891 120.363 69.819 115.517 67.5056C110.672 65.1922 105.369 63.9943 100 64C94.5549 63.993 89.1794 65.224 84.28 67.6L81.576 65.944L128 37.576Z"
                    fill="#F4F0FF"
                  />
                  <path
                    d="M127.046 107.247C125.124 114.42 120.431 120.536 114 124.249C107.569 127.962 99.9261 128.968 92.7531 127.046C85.5801 125.124 79.4644 120.431 75.7513 114C72.0383 107.569 71.0321 99.926 72.9541 92.753C74.8761 85.5799 79.5689 79.4642 86 75.7512C92.4312 72.0381 100.074 71.032 107.247 72.954C114.42 74.876 120.536 79.5687 124.249 85.9999C127.962 92.431 128.968 100.074 127.046 107.247V107.247ZM115.122 90.1459C114.756 89.7684 114.32 89.4665 113.838 89.2576C113.356 89.0487 112.837 88.9368 112.312 88.9283C111.787 88.9198 111.265 89.015 110.776 89.2082C110.288 89.4015 109.842 89.6891 109.465 90.0546L96.3859 102.701L93.2916 97.341C92.7605 96.4211 91.8857 95.7499 90.8597 95.475C89.8337 95.2 88.7405 95.344 87.8206 95.8751C86.9007 96.4062 86.2295 97.281 85.9546 98.307C85.6796 99.333 85.8236 100.426 86.3547 101.346L90.7351 108.922C91.1905 109.713 91.8202 110.389 92.5763 110.899C93.3324 111.41 94.195 111.741 95.0984 111.869C96.0019 111.996 96.9224 111.915 97.79 111.633C98.6576 111.351 99.4494 110.874 100.105 110.24L115.03 95.803C115.408 95.4376 115.71 95.0014 115.919 94.5193C116.128 94.0372 116.24 93.5187 116.248 92.9934C116.256 92.468 116.161 91.9462 115.968 91.4576C115.775 90.969 115.487 90.5233 115.122 90.1459V90.1459Z"
                    fill="#28A745"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_4_4683"
                  x="-135"
                  y="-213"
                  width="398"
                  height="852"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="2" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4_4683"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4_4683"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_4_4683">
                  <rect width="128" height="128" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className="text-center">
            <h3>Congratulations! You have created a new ping.</h3>
            <p>
            Get ready to stay ahead of the game! Our property alert service will now send you an email every morning, keeping you informed about any changes in prices, new properties, and unlisted properties for the suburbs you have selected.
            </p>

            <button
              className="btn btn-outline-primary col-4"
              type="button"
              onClick={() => handleClose()}
            >
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SuccessPing;
