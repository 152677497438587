import React from "react";

import SearchResults from "./SearchResults";
import Chip from "./Chip";

const SearchBar = (props) => {
  return (
    <div>
      <div className="form-control p-0 input-grou  d-flex flex-wrap">
        <Chip suburbChoices={props.suburbChoices} choices={props.choices} />
        <input
          className="form-control border-0 border-left ml-0"
          type="search"
          placeholder="Enter suburb names"
          autoFocus
          required
          value={props.searchTerm}
          // sending the search input data back to the handle event
          onChange={(event) => props.searchChange(event)}
          // onClear = {props.clear}
        ></input>
      </div>

      {props.searchResults.length > 0 && (
        <div className="container position-relative">
          <SearchResults
            searchResults={props.searchResults}
            choices={props.choices}
          />
        </div>
      )}

      <small className="form-text text-muted">
        You can add multiple suburbs to track (max of 10)
      </small>
    </div>
  );
};

export default SearchBar;
