import React from "react";
import { useState, useEffect } from "react";
import PingCard from "./PingCard";
import Ping from "./Ping";
import Announcement from "./Announcement";
import { Spinner } from "react-bootstrap";
import SuccessPing from "./successPing";
import PayementSuccess from "./Stripe/PayementSuccess";
import PayementFailed from "./Stripe/PayementFailed";
import ContinueSubscription from "./ContinueSubscription";
import mixpanel from "mixpanel-browser";
import Navbar from "./Navbar";
import Banner from "./banner";

const HomePage = () => {
  //list of suburbs chosen
  const [pings, setPings] = useState([]);
  const [suburbs, setSuburbs] = useState([]);
  const [pingChange, setPingChange] = useState(0);

  const accessToken = localStorage.getItem("BE_token");
  const userId = localStorage.getItem("user_id");

  const [isLoading, setIsLoading] = useState(true);
  const [fname, setFname] = useState("");
  // const [lname, setLname] = useState("");
  const [notificationEmail, setNotificationEmail] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [trialEndDate, setTrialEndDate] = useState("");
  const [isSubscribed, setIsSubscribed] = useState();
  const [isRentalSubscribed, setIsRentalSubscribed] = useState();

  const [showBanner, setShowBanner] = useState(false);
  const [successPing, setSuccessPing] = useState(false);
  const [successPayment, setSuccessPayment] = useState(false);
  const [failedPayement, setFailedPayement] = useState(false);

  //mixpanel tracking
  mixpanel.identify(userId);

  useEffect(() => {
    const isIdentified = localStorage.getItem("isIdentified") === "true";
    if (notificationEmail && !isIdentified) {
      mixpanel.people.set({
        firstName: fname,
        trialEndDate: trialEndDate,
        email: notificationEmail,
        subscriber: isSubscribed,
      });
      localStorage.setItem("isIdentified", "true");
    }
  }, [notificationEmail]);

  //code to call the user details api from the backend and save it in the state
  async function getUser() {
    // Check if the token exists
    if (accessToken && userId) {
      try {
        // Make a GET request with the token in the header
        const response = await fetch(
          `https://app.proping.com.au/users/${userId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Check if the response is positive
        if (!response.ok) {
          throw new Error(response.status);
        }

        // Parse the response data as JSON and log it
        const data = await response.json();

        // setting the notificationEmail to state
        setFname(data.given_name);
        // setLname(data.family_name);
        setNotificationEmail(data.email);
        setStripeId(data.stripe_customer_id);
        setTrialEndDate(data.trial_end_date);
        setIsSubscribed(
          data.last_invoice_paid ||
            (data.organization_id !== null && data.organization_id !== "")
        );
        setIsRentalSubscribed(data.organization_rental_customer);

        if (
          data.last_invoice_paid ||
          (data.organization_id !== null && data.organization_id !== "")
        ) {
          setShowBanner(false);
        } else {
          setShowBanner(true);
        }
      } catch (error) {
        console.error("Error fetching pings:", error);
        localStorage.removeItem("BE_token");
        window.location.reload();
      }
    } else {
      console.error("Backend or UserId token not found in local storage");
    }
  }

  // function to calculate the trial days left
  function calculateDaysLeft(endDateStr) {
    const endDate = new Date(endDateStr);
    const currentDate = new Date();
    const timeDiff = endDate.getTime() - currentDate.getTime();
    const numOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
    const daysLeft = Math.max(0, numOfDays); // Set negative value to 0

    return daysLeft;
  }

  const daysToTrial = calculateDaysLeft(trialEndDate);

  async function getPings() {
    // Retrieve the backend token from the local storage
    // Check if the token exists

    if (accessToken) {
      try {
        // Make a GET request with the token in the header
        const response = await fetch("https://app.proping.com.au/pings", {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Check if the response is positive
        if (!response.ok) {
          throw new Error(response.status);
        }

        // Parse the response data as JSON and log it
        const data = await response.json();
        // setting the pings data for the user
        // console.log(data)
     
        if (response.ok) {
          setPings(data);

          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching pings:", error);
        localStorage.removeItem("BE_token");
        window.location.reload();
      }
    } else {
      console.error("Backend token not found in local storage");
    }
  }

  useEffect(() => {
    // Call the function to make the request and log the data
    getPings();
    getUser();

    //check if payment is success or failure
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("success") === "true") {
      setSuccessPayment(true);
    }
    if (urlParams.get("canceled") === "true") {
      setFailedPayement(true);
    }
  }, [pingChange]);

  // create a new ping by submitting to the backend
  async function handleApplySuburbChoices(
    suburbChoices,
    isJustListed,
    isJustSold,
    isPriceChange,
    isUnlisted,
    isUnderOffer,
    isOver90Days,
    minPrice,
    isRental
  ) {
    const accessToken = localStorage.getItem("BE_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const actionTypes = [];
    isJustListed && actionTypes.push("just listed");
    if (isRental && isJustSold) {
      actionTypes.push("Leased");
    } else if (!isRental && isJustSold) {
      actionTypes.push("Sold");
    }
    isPriceChange && actionTypes.push("price change");
    isUnlisted && actionTypes.push("unlisted");
    isUnderOffer && actionTypes.push("under offer");
    if (isRental && isOver90Days) {
      actionTypes.push("Over 60 Days");
    } else if (!isRental && isOver90Days) {
      actionTypes.push("Over 90 days");
    }


    const dataObject = {
      suburbs: suburbChoices,
      choices: actionTypes,
      ...(isRental ? { min_rental_value: minPrice } : { min_sales_value: minPrice }),  // Conditional property
      rental_ping:isRental
    };

    try {
      const response = await fetch("https://app.proping.com.au/pings", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataObject),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.ok) {
        setPingChange(pingChange + 1);
        setSuccessPing(true);
        mixpanel.track("ping-saved", {
          suburbs: suburbChoices,
          user: notificationEmail, 
          isRental:isRental,
            minPrice:minPrice
        });
      }

      return response.ok;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  // editing a ping by submitting to the backend
  async function handleEditSuburbChoices(
    suburbChoices,
    isJustListed,
    isJustSold,
    isPriceChange,
    isUnlisted,
    isUnderOffer,
    isOver90Days,
    minPrice,
    isRental,
    cardId
  ) {
    const accessToken = localStorage.getItem("BE_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const actionTypes = [];
    isJustListed && actionTypes.push("just listed");
    if (isRental && isJustSold) {
      actionTypes.push("Leased");
    } else if (!isRental && isJustSold) {
      actionTypes.push("Sold");
    }
    isPriceChange && actionTypes.push("price change");
    isUnlisted && actionTypes.push("unlisted");
    isUnderOffer && actionTypes.push("under offer");
    if (isRental && isOver90Days) {
      actionTypes.push("Over 60 Days");
    } else if (!isRental && isOver90Days) {
      actionTypes.push("Over 90 days");
    }

    const dataObject = {
      suburbs: suburbChoices,
      choices: actionTypes,
      ...(isRental ? { min_rental_value: minPrice } : { min_sales_value: minPrice }),  // Conditional property
      rental_ping:isRental
    };

    try {
      const response = await fetch(
        `https://app.proping.com.au/pings/${cardId}`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(dataObject),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (response.ok) {
        setPingChange(pingChange + 1);
        mixpanel.track("ping-edited", {
          suburbs: suburbChoices,
          isRental:isRental,
          minPrice:minPrice
        });
      }

      return response.ok;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  //delete ping --
  async function handleDelete(cardId) {
    const accessToken = localStorage.getItem("BE_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await fetch(
        `https://app.proping.com.au/pings/${cardId}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.ok) {
        setPingChange(pingChange + 1);
     
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }
  //-- end delete pings

  function handleBannerClose() {
    setShowBanner(false);
  }

  return (
    <div>
      <div>
        {showBanner && (
          <Banner
            isSubscribed={isSubscribed}
            daysToTrial={daysToTrial}
            email={notificationEmail}
            handleBannerClose={handleBannerClose}
          />
        )}
        <Navbar
          email={notificationEmail}
          customerId={stripeId}
          isSubscribed={isSubscribed}
          isRentalSubscribed={isRentalSubscribed}
        />

        <div className="container-sm mt-4">
          {
            // first day welcome message
            !isSubscribed && daysToTrial === 14 && (
              <Announcement
                email={notificationEmail}
                title={`Welcome to Proping, ${fname}`}
                text="Start by creating your Ping. We've initiated a 14-day free trial to help you get going."
                buttonText=""
                video={true}
              />
            )
          }
          {/* when trial ends show this message */}
          {!isSubscribed && daysToTrial === 0 && (
            <Announcement
              email={notificationEmail}
              title="Your 14 day trial has ended"
              text="Your free trial for Proping has ended. Subscribe to continue receiving your daily emails."
              buttonText="Upgrade for $20 / month"
              video={false}
            />
          )}
          <div className="container-sm bg-light p-0 pt-4 pb-2 d-flex justify-content-between align-items-center">
            <h4>Your ping</h4>

            {/* Create new ping button */}
            {/* <Ping
              apply={handleApplySuburbChoices}
              notificationEmail={notificationEmail}
              isRentalSubscribed={isRentalSubscribed}
            /> */}
          </div>
          <div className="border-bottom"></div>
          {/* Pings that have been created already show up here */}
          {isLoading ? (
            <div className="d-flex justify-content-center mt-4">
              <Spinner animation="border" />
            </div>
          ) : pings.length ? (
            <div className="container p-0 pt-3">
              <div className="row row-cols-md-1">
                {pings
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((c) => (
                    <div key={c.id} className="col-md mb-4">
                      <PingCard
                        key={c.id}
                        suburbs={c.suburbs}
                        choices={c.choices}
                        //rental attributes
                        minRentalPrice={c.min_rental_value}
                        minSalesPrice={c.min_sales_value}
                        isRental={c.rental_ping}
                        isRentalSubscribed={isRentalSubscribed}
                        apply={handleEditSuburbChoices}
                        delete={handleDelete}
                        id={c.id}
                        notificationEmail={notificationEmail}
                        daysToTrial={daysToTrial}
                        isSubscribed={isSubscribed}
                      />
                    </div>
                  ))} 
              </div>
            </div>
          ) : (
            // if no pings then show empty state here
            <div className="d-flex flex-column align-items-center m-4 ">
              <img
                src={require("../assets/EmptyState.png")}
                className="img-fluid emptyStateImage mt-5"
                alt=""
                style={{ width: "250px" }}
              />
              <h4 className="pt-4">Create your first ping</h4>

              <p className="pt-1 text-center description text-secondary">
                Add suburbs that you would like to monitor and we will send you
                daily updates.
              </p>
              <div className="pt-1">
                <Ping
                  apply={handleApplySuburbChoices}
                  notificationEmail={notificationEmail}

                  isRentalSubscribed={isRentalSubscribed}

                />
              </div>
            </div>
          )}
          {successPing && <SuccessPing show={true} />}
          {successPayment && <PayementSuccess show={successPayment} />}
          {failedPayement && (
            <PayementFailed show={true} email={notificationEmail} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
